import React from "react";
import { Currency } from "../../lib";

const RelatedInfluencers = ({influencers}) => {
    return (
        <div className="bg-white rounded-lg shadow-[0px_16px_32px_rgba(0,0,0,0.05)] p-8 mt-8">
            <div className="flex items-center gap-4 mb-4">
                <div className="text-lg text-slate-700 ">Influencer Terkait</div>
                <div className="text-sm text-slate-500"><span className="text-primary font-bold">76</span> influencer menjual produk ini</div>
            </div>

            <div className="overflow-x-auto">
                <table className="table min-w-full">
                    <thead className="mb-8">
                        <tr className="text-left">
                            <td className="rounded-l-xl text-slate-500 text-sm p-2">Influencer</td>
                            <td className="rounded-r-xl text-slate-500 text-sm p-2">Volume Penjualan</td>
                            <td className="rounded-r-xl text-slate-500 text-sm p-2">Omset Penjualan</td>
                            <td className="rounded-r-xl text-slate-500 text-sm p-2">Pengikut</td>
                            <td className="rounded-r-xl text-slate-500 text-sm p-2">GPM Video</td>
                            <td className="rounded-r-xl text-slate-500 text-sm p-2 text-right">GPM Live</td>
                        </tr>
                    </thead>
                    <tbody className="mt-4">
                        {
                            influencers.map((influencer, i) => (
                                <tr key={i}>
                                    <td className="p-2 flex items-center gap-2">
                                        <img src={influencer.photo_url} alt={influencer.name} className="h-12 rounded-lg object-cover aspect-square bg-slate-100" />
                                        <div className="flex flex-col grow">
                                            <div className="text-slate-700">{influencer.name}</div>
                                            <div className="text-slate-500 text-xs">@{influencer.username}</div>
                                        </div>
                                    </td>
                                    <td className="p-2 text-sm">Rp {Math.round(Math.random() * 9999999 / 1000000).toFixed(2)}jt</td>
                                    <td className="p-2 text-sm">Rp {Math.round(Math.random() * 9999999 / 1000000).toFixed(2)}jt</td>
                                    <td className="p-2 text-sm">
                                        {Math.round(influencer.followers / 1000000).toFixed(2)}jt
                                    </td>
                                    <td className="p-2 text-sm">
                                        {Currency(Math.round(Math.random() * 9999)).encode()} -
                                        {Currency(Math.round(Math.random() * 99999)).encode()}
                                    </td>
                                    <td className="p-2 text-sm text-right">
                                        {Currency(Math.round(Math.random() * 9999)).encode()} -
                                        {Currency(Math.round(Math.random() * 99999)).encode()}
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RelatedInfluencers