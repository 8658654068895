import React, { useState } from "react";
import Header from "../components/Header";
import { IconBox, IconCalendar, IconMoneybag, IconSpeakerphone } from "@tabler/icons-react";
import { BiComment, BiMoney } from "react-icons/bi";
import config from "../config";
import ReactCountryFlag from "react-country-flag";
import Currency from "../lib/Currency";
import RatingStars from "../components/RatingStars";
import Footer from "../components/Footer";
import Toggler from "../components/Toggler";
import RelatedInfluencers from "./ProductDetail/RelatedInfluencers";

const ProductDetail = () => {
    const [dateFilter, setDateFilter] = useState('7 Hari');
    const viralInfluencers = [
        {
            name: "Willie Salim",
            username: "williesalim",
            photo_url: "https://echotik.live/_next/image?url=https%3A%2F%2Fd304ly0se1sg9m.cloudfront.net%2Fuser-avatar%2F876%2FMS4wLjABAAAAdPCAdcoGomXKnrJxNxkyG0hVJnasKEsL1FnRzVxI162mmVeebQA3SyhBBpvDOnlq.jpeg%3FExpires%3D1724130875%26Signature%3DMiSVXfUvzKO9swGCbz57klSOJnYcMcuswWNsNmSXz5EdUOqDRe~3ZRT2NgxSCd4MRcu8fLFDmVD~8qSs3hvdlHiqent936JWezsK-RTTr9VQA1jITaH~tHH3mWeP1vuYgygWxhShxFXzRjU8h5Hd5jepNtw3~A-f6d-Fqy-3i0d-BjGxYLbVnF7tH41-ae6wzCGCXEwA4lGJJ~Nj40bousqomdhm-YdjrN4IRPF7v3MqoZebwFr0ufVCkQtvqnkvjmW4cza5T0lbEVnpiPC3MBdi2wlVYWgsZxOEgHmRqUIQ1dQ4CV7M64ch8uRCgjjplO8PDqUvU4xHY0hNTFgBjw__%26Key-Pair-Id%3DK18GL1X40JAX3H&w=128&q=75",
            gmv: 57430930,
            followers: 5785559,
            followers_growth: 700330,
        },
        {
            name: "Mamanya Kamari",
            username: "jennifer.copen",
            photo_url: "https://echotik.live/_next/image?url=https%3A%2F%2Fd304ly0se1sg9m.cloudfront.net%2Fuser-avatar%2F987%2FMS4wLjABAAAACarElN1POjHr1tIi3cTaUXmrP1spliG9K38eWQzN4A2fGCwq8dAJvnHU9P6EiXUs.jpeg%3FExpires%3D1724130875%26Signature%3DdMeEUQpUszV9q6NZ-rqfdVoaHBEhwy6PWL-35NyVmhfp3gfKZyiW1u~0M~12OoyxR6NYQspvdUJx8OxGvrOyzpmiGR9os5nipIqY5p9ySILuPDIELr2VtznYTGatAGOReDZo84vwMAj-88PqfNmORgKQ7AHAmc0OgBbr0YZ6-ViiTQhU4c2geAJKl4YPX-i462yZN~SNONgWsA7AFCo9J-yx9p7Cns5ayQgQ-N05TjZTRrtqba50kEvdHUMJISjvCQerrec0junoVRkwBi~CGYAKBAgJmgR7jIOk3x6tNAQZnkXB8zNcWQUl~u0dqQESQoFanaMs4oLhw0mgEvSZDQ__%26Key-Pair-Id%3DK18GL1X40JAX3H&w=128&q=75",
            gmv: 4339230,
            followers: 5012390,
            followers_growth: 694203,
        },
        {
            name: "Neng_Fitoy",
            username: "neng_fitoy",
            photo_url: "https://echotik.live/_next/image?url=https%3A%2F%2Fd304ly0se1sg9m.cloudfront.net%2Fuser-avatar%2F412%2FMS4wLjABAAAA7Ok_AY4ixwCPShIU0QV-9AmyBfcVq5JKxRY3Us-T8pqsuu5ox_MHu3BZYrXtC3n2.jpeg%3FExpires%3D1724131238%26Signature%3DVJ7x~kTj6-xGZZaq9u5KM6ibNTu~8DTG1l9wNser37UuhUbS37ZkXUxzZXvjPlkm1RIFThgip4FQml9D9L7BKumkxGGinQJDDC6DmRsGER5ca1ZzF7X8-FHlhvSuxshcuc3NYTyY0UcbFr9x4QWIFlhQHhf4tY5XchhN7vKiP2saMI4nwgKVxJQBYl~PT053S-97CCdO9O1DEGxgUjZ-cdjGLfNf6e2mWnu2ORwerT4s~HK91fxmoVfbTSwyXqkfd0IARfPBTncJ2Lac~aUKxZbnjdQCCZWVOcGmzDHbR37Sc6X~Sl3EkmpZUPZiu2a33MnxtqnqUKpV8BQDdO3GIg__%26Key-Pair-Id%3DK18GL1X40JAX3H&w=128&q=75",
            gmv: 4890,
            followers: 66550449,
            followers_growth: 493480,
        },
        {
            name: "Mamah Papah Angels",
            username: "mamah_papah_angels",
            photo_url: "https://echotik.live/_next/image?url=https%3A%2F%2Fd304ly0se1sg9m.cloudfront.net%2Fuser-avatar%2F784%2FMS4wLjABAAAAnMavhSpd-Z6o6fXmP3CpOO6a2CMOulGDwojqT3XZL_RVmiDGG8skmMsDKYasXxUM.jpeg%3FExpires%3D1724131239%26Signature%3Dgu~mBRcfCLdUreywpzrE9WdIO23JIKWe5CDTrP399b8Rp6kf5~46DzM~ulxvLIfjY1EPfqC8~Hc40z-TQSHHh18m~QLf2ptL4kkP2w7M79rAgIWVW8PZJ8emgjEnfivNHWDxI7UgbRkonRlP5UIKYz3M5D8xLPbmnnJIgL2-z5WxJG5sl3wvJ1ofshutA-1hYR9Tcp20HuwWlJHdbH4azK6j-ssvIuF6lKvFQhpOcdXdwQLN~GJm~b15f~4iUT3E2MXJNqNIv9dHiqXrhUDy0nbJQcs3-OmF4Zg3-fToq9wDOUX1RQhK1RuBrxeWG5APCEX8f11U1SpS2AOWwngy5w__%26Key-Pair-Id%3DK18GL1X40JAX3H&w=128&q=75",
            gmv: 4119239,
            followers: 30550449,
            followers_growth: 400500,
        },
    ]

    return (
        <>
            <Header />
            <div className="absolute top-20 left-0 right-0">
                <div className="flex items-center gap-4 bg-white p-8 px-16 mobile:p-4">
                    <IconBox color={config.primaryColor} size={28} />
                    <div className="text-slate-700 text-lg font-medium flex grow">Rincian Produk</div>
                </div>

                <div className="p-8 px-16">
                    <div className="bg-white rounded-lg shadow-[0px_16px_32px_rgba(0,0,0,0.05)] p-8 flex gap-8">
                        <div className="h-64 aspect-square rounded-lg bg-slate-200"></div>
                        <div className="flex flex-col grow gap-1">
                            <div className="text-xs text-slate-500 flex items-center gap-2">
                                <ReactCountryFlag countryCode="ID" />
                                Indonesia
                            </div>
                            <div className="text-lg text-slate-700 font-medium">
                                JSB01 Kapal Api Special Mix / Good Day / ABC Susu / Luwak Kopi Promo
                            </div>
                            <div className="flex items-center gap-4 text-sm text-slate-500 mt-1">
                                <BiMoney />
                                <div className="text-primary text-base font-medium">{Currency(151000).encode()}</div>
                                <div className="line-through">{Currency(170000).encode()}</div>
                            </div>
                            <div className="flex items-center gap-4 text-sm text-slate-500 mt-1">
                                <BiComment />
                                <div>254 comments</div>
                            </div>
                            <div className="h-6"></div>
                            <div className="text-slate-500">Ulasan</div>
                            <div className="flex gap-1 items-center">
                                <RatingStars value={2.3} />
                                <div className="ms-4 font-medium text-slate-700">2.3</div>
                                <div className="text-xs text-slate-500">/</div>
                                <div className="text-xs text-slate-500">5</div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2 w-4/12">
                            <div className="flex items-center gap-4">
                                <div className="text-sm text-slate-500 flex grow">Jumlah Penjualan</div>
                                <div className="text-2xl font-bold text-primary">20.1K</div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="text-sm text-slate-500 flex grow">GMV</div>
                                <div className="text-2xl font-bold text-primary">Rp 4.20B</div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="text-sm text-slate-500 flex grow">Total Influencer</div>
                                <div className="text-2xl font-bold text-primary">11</div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="text-sm text-slate-500 flex grow">Total Videos</div>
                                <div className="text-2xl font-bold text-primary">14</div>
                            </div>
                            <div className="flex items-center gap-4">
                                <div className="text-sm text-slate-500 flex grow">Total Live</div>
                                <div className="text-2xl font-bold text-primary">0</div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white rounded-lg shadow-[0px_16px_32px_rgba(0,0,0,0.05)] p-8 mt-8">
                        <div className="flex items-center gap-4">
                            <div className="text-lg text-slate-700 flex grow">Summary</div>
                            <div className="text-lg text-slate-500">Waktu</div>
                            <Toggler
                                value={dateFilter}
                                setValue={setDateFilter}
                                options={['7 Hari','15 Hari','30 Hari','90 Hari']}
                            />
                            <Toggler
                                value={dateFilter}
                                setValue={setDateFilter}
                                options={[{
                                    value: 'Custom',
                                    render: <div className="flex items-center gap-2"><IconCalendar size={18} /> Custom</div>,
                                }]}
                            />
                        </div>

                        <div className="flex gap-2 items-center mt-4 w-10/12 mobile:w-full">
                            <div className="p-4 flex flex-col grow basis-96 items-center border-r-2">
                                <div className="text-4xl font-bold text-primary">19.7 K</div>
                                <div className="text-xs text-slate-500">Peningkatan Penjualan</div>
                            </div>
                            <div className="p-4 flex flex-col grow basis-96 items-center border-r-2">
                                <div className="text-4xl font-bold text-primary">Rp 1.43 M</div>
                                <div className="text-xs text-slate-500">Peningkatan Omset Penjualan</div>
                            </div>
                            <div className="p-4 flex flex-col grow basis-96 items-center border-r-2">
                                <div className="text-4xl font-bold text-primary">0</div>
                                <div className="text-xs text-slate-500">Live Baru</div>
                            </div>
                            <div className="p-4 flex flex-col grow basis-96 items-center">
                                <div className="text-4xl font-bold text-primary">4</div>
                                <div className="text-xs text-slate-500">Video Baru</div>
                            </div>
                        </div>
                    </div>

                    <RelatedInfluencers influencers={viralInfluencers} />
                </div>

                <Footer />
            </div>
        </>
    )
}

export default ProductDetail