import React from 'react';
import { IconStarFilled, IconStarHalf, IconStar } from '@tabler/icons-react';

const RatingStars = ({ value = 3.4, max = 5, size = 18 }) => {
    const fullStars = Math.floor(value);
    const partialStar = value % 1;
    const emptyStars = max - fullStars - Math.ceil(partialStar);

    return (
        <div className="flex gap-2">
            {/* Render full stars */}
            {Array.from({ length: fullStars }).map((_, index) => (
                <IconStarFilled key={`full-${index}`} className="text-yellow-500" size={size} />
            ))}

            {/* Render a partially filled star */}
            {partialStar > 0 && (
                <div className="relative">
                    <IconStar className="text-yellow-500 absolute" size={size} />
                    <IconStarFilled
                        className="text-yellow-500"
                        style={{
                            clipPath: `inset(0 ${partialStar * 100}% 0 0)`,
                        }}
                        size={size}
                    />
                </div>
            )}

            {/* Render empty stars */}
            {Array.from({ length: emptyStars }).map((_, index) => (
                <IconStar key={`empty-${index}`} className="text-yellow-500" size={size} />
            ))}
        </div>
    );
};

export default RatingStars;
