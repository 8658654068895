import React from "react";

const Toggler = ({value, setValue, options = []}) => {
    return (
        <div className="flex">
            {
                options.map((opt, o) => {
                    let optType = typeof opt;
                    let theOpt = optType === 'string' ? opt : opt.value;
                    return (
                        <div 
                        key={o} 
                        onClick={() => {
                            if (setValue) {
                                setValue(theOpt);
                            }
                        }}
                        className={`p-2 px-6 border-t border-b text-sm cursor-pointer ${theOpt === value ? 'bg-primary text-white font-medium' : ''} ${o == 0 ? 'border-l rounded-l-lg' : ''} ${o === options.length - 1 ? 'border-r rounded-r-lg' : ''}`}
                        >{optType === 'string' ? opt : opt.render}</div>
                    )
                })
            }
        </div>
    )
}

export default Toggler;