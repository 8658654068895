const config = {
    // baseUrl: 'http://127.0.0.1:8000',
    appName: "Publish.co.id",
    baseUrl: 'https://broadcast-api.zainzo.com',
    whatsappUrl: 'https://whatsapp.zainzo.com',
    // whatsappUrl: 'http://127.0.0.1:2024',
    // primaryColor: "#198754",
    primaryColor: "#3B19CC",
    google_client_id: "448841353104-2jnn7p65maru7frsgmrttae2s8tb9lea.apps.googleusercontent.com",
    google_analytics_tracking_code: "UA-107986295-1",
    whatsapp_admin: "628159830008",
}

export default config